<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-4">
          <h1 class="headline black--text">{{ $t('menu.myDocuments') }}</h1>
          <v-spacer />
          <v-btn rounded color="primary" depressed to="/patients/documents/upload">{{
            $t('documents.upload_document')
          }}</v-btn>
        </v-toolbar>
        <v-card>
          <v-sheet v-if="isLoading" height="300">
            <v-layout fill-height align-center justify-center>
              <v-progress-circular width="2" indeterminate color="primary" />
            </v-layout>
          </v-sheet>
          <v-simple-table v-if="!isLoading">
            <template v-slot:default>
              <thead class="grey lighten-4">
                <tr>
                  <th>{{ $t('messages.to') }}</th>
                  <th>{{ $t('common.name') }}</th>
                  <th>{{ $t('common.type') }}</th>
                  <th>{{ $t('common.professional') }}</th>
                  <th>{{ $t('common.date') }}</th>
                  <th width="1%">{{ $t('common.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!isLoading">
                  <tr v-for="item in documents" :key="item.id">
                    <td v-text="documentReceiver(item)" />
                    <td>{{ item.name }}</td>
                    <td>
                      <v-chip small class="grey--text grey lighten-4 font-weight-bold text--darken-1 text-uppercase">{{
                        item.extension
                      }}</v-chip>
                    </td>
                    <td>
                      <d-avatar
                        :text="item.professional.name"
                        size="24"
                        rounded
                        :two-letter="true"
                        class="float-left mr-2"
                        :src="item.professional.image"
                      />
                      {{ item.professional.name }}
                    </td>
                    <td>{{ item.createdAt | moment('DD/MM/YYYY') }}</td>
                    <td>
                      <v-btn icon @click="openDocument(item)">
                        <v-progress-circular
                          v-if="item.downloading"
                          size="25"
                          width="1"
                          rotate="3"
                          indeterminate="true"
                          color="primary"
                        />
                        <v-icon else>mdi-eye</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="!documents || !documents.length">
                    <td colspan="5" class="pa-5 text-center">{{ $t('documents.no_documents') }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <template v-if="totalResults && !isLoading">
          <div class="my-5 text-center">
            <v-pagination
              v-if="totalPages > 1"
              v-model="currentPage"
              :length="totalPages"
              text
              :total-visible="6"
              circle
              color="primary"
              @input="changePage"
            />
          </div>
        </template>
      </v-card>
    </v-row>
    <v-overlay :value="overlay">
      <v-toolbar>
        <v-btn icon @click="overlay = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-btn icon @click="overlay = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-overlay>
  </v-container>
</template>
<script>
import Alerts from '@/mixins/Alerts';
import DAvatar from '@/components/DAvatar.vue';
import { getDocumentsUrl, getHeader, urlPatient, displayNameEnv } from '@/config/config';
import DownloadFile from '@/mixins/DownloadFile';
import { mapGetters } from 'vuex';

export default {
  title: displayNameEnv + ' - ' + vm.$t('menu.myDocuments'),
  components: { DAvatar },
  mixins: [Alerts, DownloadFile],
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 20,
      totalPages: null,
      totalResults: null,
      overlay: false,
      documents: null,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  computed: {
    ...mapGetters({
      isEnabledChatV2Integration: 'app/getChatV2IntegrationFeatureFlag',
    }),
  },
  mounted() {
    this.loadDocuments();
  },
  methods: {
    async openDocument(document) {
      await this.download({
        url: document.url ? document.url : document.file,
        name: document.name ? document.name : document.fileName,
        type: document.type ? document.type : document.fileType,
        open: true,
        openInNewTab: true,
        download: false,
      });
    },

    changePage(page) {
      this.currentPage = page;
      this.loadDocuments();
    },

    loadDocuments() {
      this.$scrollTo('#appRoot');
      this.isLoading = true;

      this.$http
        .get(urlPatient(getDocumentsUrl, this.perPage, this.currentPage), { headers: getHeader() })
        .then(res => {
          this.documents = res.body.data;
          this.totalResults = res.body._meta.total;
          this.totalPages = Math.ceil(this.totalResults / this.perPage);
        })
        .catch(err => {
          this.$log.error(err);
          let msg = 'Ocurrió un error!';
          if (err && err.response && err.response.data) {
            msg = err.response.data.detail;
          }
          this.toastError(msg);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    documentReceiver(document) {
      return document.patient.id === this.authUser.patient.id ? this.$t('messages.me') : document.patient.name;
    },
  },
};
</script>
